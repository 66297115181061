import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  searchResult: [],
  searchTotalizers: undefined,
  leadTypes: [],
  newLeadSidebar: {
    id: undefined,
    leadType: undefined,
    visible: false,
  },
  occupations: [],
  schoolLevels: [],
  incomes: [],
  goals: [],
  origins: [],
  counters: [],
  settingsTableCustomer: undefined,
  settingsTableSidebar: {
    id: undefined,
    description: undefined,
    currentPage: undefined,
    contract_id: undefined,
    visible: false,
  },
  tableColumns: [
    { key: "customer_name", label: "Nome", stickyColumn: true },
    { key: "customer_status_name", label: "Status", sortable: true },
    { key: "last_interview_days", label: "Dias sem contato", sortable: true },
    { key: "call_reminder", label: "Lembrar", sortable: true },
    { key: "cash_flow", label: "Renda", sortable: true },
    {
      key: "monthly_savings",
      label: "Poupa mensalmente",
      fixed: 1,
      sortable: true,
    },
    { key: "goals", label: "Objetivo", sortable: true },
    { key: "consultant_name", label: "Consultor Responsável", sortable: true },
    { key: "franchise_name", label: "Franquia", sortable: true },
    { key: "email", label: "Email", sortable: true },
    { key: "anniversary_date", label: "Aniversário", sortable: true },
    { key: "last_interview_date", label: "Última reunião", sortable: true },
    { key: "became_customer_at", label: "Cliente a partir de", sortable: true },
    { key: "actions", label: "Ações", stickyColumn: true },
  ],
  sequenceColumnEnabled: true,
  openSettingsTableAprovalSidebar: false,
  statuses: [],
  customerTransferOptions: [],
};

export default {
  state,
  actions,
  getters,
  mutations,
};
