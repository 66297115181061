import { 
  READ_ACTION, 
  SUBSCRIPTIONS_STATICS, 
  CONTRACT_RESOURCE, 
  BUSINESS_INTELLIGENCE_RESOURCE,
  KPI
} from "@/constants/resources";
import { infoKPI } from "@/helpers/modalInfo";

export default [
  {
    path: "/kpi",
    name: "kpi-list",
    component: () => import("@/modules/kpi/views/KpiList.vue"),
    meta: {
      resource: KPI,
      action: READ_ACTION,
      pageTitle: "Faturômetro",
      info: infoKPI,
    },
  },
  {
    path: "/kpi/ranking-compromissos",
    name: "kpi-ranking-appoiments",
    component: () => import("@/modules/kpi/views/RankingAppoiments.vue"),
    meta: {
      resource: KPI,
      action: READ_ACTION,
      pageTitle: "Ranking de compromissos",
    },
  },
  {
    path: "/kpi/ranking-invoicing",
    name: "kpi-ranking-invoicing",
    component: () => import("@/modules/kpi/views/RankingInvoicing.vue"),
    meta: {
      resource: KPI,
      action: READ_ACTION,
      pageTitle: "Ranking de faturamento",
    },
  },
  {
    path: "/kpi/ranking-specialist",
    name: "kpi-ranking-specialist",
    component: () => import("@/modules/kpi/views/RankingSpecialist.vue"),
    meta: {
      resource: KPI,
      action: READ_ACTION,
      pageTitle: "Ranking de Especialistas",
    },
  },
  {
    path: "/kpi/ranking-pilares",
    name: "kpi-ranking-pilares",
    component: () => import("@/modules/kpi/views/RankingPilares.vue"),
    meta: {
      resource: KPI,
      action: READ_ACTION,
      pageTitle: "Ranking Campanha Pilares",
    },
  },
  {
    path: "/kpi/estatisticas-assinaturas",
    name: "kpi-subscriptions-statistics",
    component: () => import("@/modules/kpi/views/SubscriptionStatistics.vue"),
    meta: {
      resource: BUSINESS_INTELLIGENCE_RESOURCE,
      action: SUBSCRIPTIONS_STATICS,
      pageTitle: "Estatísticas de Assinatura",
    },
  },
];
