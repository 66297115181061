import {
    READ_ACTION,
    CREATE_ACTION,
    DASHBOARD_BANNER,
    ANNOUNCEMENT_RESOURCE,
    PROFILE
  } from '@/constants/resources'

  
export default [
    {
        path: '/comunicados',
        name: 'announcements-list',
        component: () => import('@/modules/system/views/AnnouncementList.vue'),
        meta: {
          resource: ANNOUNCEMENT_RESOURCE,
          action: READ_ACTION,
          pageTitle: 'Comunicados'
        }
      },
      {
        path: '/comunicados/:id?',
        name: 'announcement-save',
        component: () => import('@/modules/system/views/AnnouncementSave.vue'),
        meta: {
          resource: ANNOUNCEMENT_RESOURCE,
          action: CREATE_ACTION,
          pageTitle: 'Novo comunicado'
        }
      },
      {
        path: '/banners',
        name: 'dashboard-banners',
        component: () => import('@/modules/system/views/DashboardBannerList.vue'),
        meta: {
          resource: DASHBOARD_BANNER,
          action: CREATE_ACTION,
          pageTitle: 'Gerenciamento de banners'
        }
      },
      {
        path: '/permissoes/perfil/:id?',
        name: 'access-profile',
        component: () => import('@/modules/access_management/views/NewAccessProfile.vue'),
        meta: {
          resource: PROFILE,
          action: CREATE_ACTION,
          pageTitle: 'Novo perfil de acesso'
        }
      },
      {
        path: '/permissoes/usuarios/:id',
        name: 'access-profile',
        component: () => import('@/modules/access_management/views/AcessProfileUsersList.vue'),
        meta: {
          resource: PROFILE,
          action: CREATE_ACTION,
          pageTitle: 'Gerenciamento de usuários'
        }
      },
]